
export const CHINESE_ENGLISH_PINYIN = 'Chinese, English & Pinyin'
export const CHINESE_ENGLISH = 'Chinese & English'
export const CHINESE_PINYIN = 'Chinese & Pinyin'
export const CHINESE = 'Chinese'
export const ENGLISH = 'English'

export const languageOptions = [
  CHINESE,
  ENGLISH,
  CHINESE_ENGLISH,
  CHINESE_PINYIN,
  CHINESE_ENGLISH_PINYIN,
]

export interface Title {
  chinese?: string;
  english?: string;
  pinyin?: string;
}

export interface PptGenConfig {
  useChinese?: boolean;
  useEnglish?: boolean;
  usePinyin?: boolean;
  theme?: any
}