import { styled } from '@mui/system';
import { Grid, TextareaAutosize, TextField, Typography, Button, Hidden, Card, CardContent } from '@mui/material'


export const RootContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  height: '100%',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  fontSize: '2rem',
}));

export const SubHeading = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  fontSize: '1.5rem',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const StyledContainer = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const InputContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: '1fr',
  }
}));

export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  resize: 'vertical',
  [theme.breakpoints.down('xs')]: {
    marginTop: theme.spacing(2),
  }
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: 'auto',
}));