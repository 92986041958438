import { styled } from '@mui/system';
import { Typography } from '@mui/material'


export const LandingPageContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  height: '100%',
}));

export const Heading = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  fontSize: '2rem',
}));

export const SubHeading = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  fontSize: '1.5rem',
}));

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr',
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: '1fr',
  }
}));
