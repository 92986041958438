import { useState } from 'react'
import { Grid, Button, Typography } from '@mui/material'
import { RootContainer, StyledTextArea, OutputContainer, Line, ButtonsContainer } from './pinyin-convertor.styled';
import { isChinese, convertToPinyinChineseLyrics, formatPinyinChineseLyrics, generateNumberRange } from '../../common/utils'
import { Switch, Dropdown } from '../../components'
import { PinyinConvertorType } from './types'

export const PinyinConvertor = (props: PinyinConvertorType) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [rawText, setRawText] = useState('')

  const [output, setOutput] = useState<any[]>([])

  const [swapSwitch, setSwapSwitch] = useState(true)
  const [pinyinOnly, setPinyinOnly] = useState(true)
  const [alignPinyin, setAlignPinyin] = useState(true)

  const [copySuccess, setCopySuccess] = useState(false)
  const [spacing, setSpacing] = useState(1)

  const showOutput = output.length > 0;

  const TextAreaHandler = (text: string) => {
    setRawText(text)
    console.log('rawText: ', rawText)
    if (isChinese(text)) {
      setErrorMessage('')
      setCopySuccess(false)

      setOutput(convertToPinyinChineseLyrics(text))
    } else {
      setErrorMessage('Please enter chinese characters')
    }
  }

  const copyToClipboard = (output: any) => {
    const lyrics = formatPinyinChineseLyrics(swapSwitch, pinyinOnly, alignPinyin, output)
      .join().replace(",", "")
    navigator.clipboard.writeText(lyrics)
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 1000)
  }

  return (
    <RootContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Chinese to Pinyin Convertor</Typography>
          <Typography variant="body2">Save time from spacing those pinyin manually. Just paste your Chinese lyrics and get your pintin aligned automatically.</Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledTextArea
            minRows={10}
            id="lyrics"
            placeholder="Enter your Chinese lyrics here"
            onChange={e => TextAreaHandler(e.target.value)}
          />
        </Grid>

        {showOutput && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ ml: 3 }} >Settings:</Typography>
            <ButtonsContainer>
              {/* <Dropdown
                disabled={output.length > 0}
                label="Spacing"
                value={spacing}
                setValue={setSpacing}
                values={generateNumberRange(1, 5)}
              /> */}
              <Switch setValue={setSwapSwitch} label="Swap Pinyin/Chinese"/>
              <Switch setValue={setPinyinOnly} label="Pinyin Only"/>
              <Switch setValue={setAlignPinyin} label="Disable pinyin alignment for copy"/>
              <Button color="primary" variant="outlined" onClick={() => copyToClipboard(output)} sx={{ height: '40px', mt: 2 }}>
                {copySuccess ? "Copied" : "Copy"}
              </Button>
            </ButtonsContainer>
          </Grid>
        )}

        {showOutput && (            
          <Grid item xs={12}>            
            <Typography variant="h5" sx={{ ml: 3 }} >Output:</Typography>
            <OutputContainer>
              {!errorMessage && output.map((line, id) =>
                <Line key={id}>
                  {line.map(({ chinese, pinyin }: any, index: number) =>
                    <span key={index}>
                      <span>
                        {swapSwitch ? pinyinOnly && chinese : pinyin}
                      </span><br />
                      <span>
                        {swapSwitch ? pinyin: pinyinOnly && chinese}
                      </span>
                    </span>
                  )}
                </Line>
              )}
              {errorMessage && <Typography variant="body1">{errorMessage}</Typography>}
            </OutputContainer>
          </Grid>
        )}
      </Grid>
    </RootContainer>
  )
}

