import Pinyin from 'chinese-transform-pinyin'
import { saveAs } from 'file-saver'

// function to check if text is in chinese
export const isChinese = (text: string) => text.match(/[\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF]+/g)

// function to split string to array for chinese/pinyin mapping
export const stringToArray = (text: string): any[] => text.replace(/\n\r?/g, '[newline]').split('[newline]')

// function to format lyrics for copy to clipboard
export const formatPinyinChineseLyrics = (swapSwitch: boolean, pinyinOnly: boolean, alignPinyin: boolean, mappedLyrics: any[]) => {
  let lyrics = ""
  return mappedLyrics.map((line: any) => {
    line.map(({ chinese, pinyin }: any) =>
      lyrics += `${swapSwitch ? pinyinOnly ? chinese : "" : dictionaryCorrection(chinese, pinyin)}`
        + `${alignPinyin ? '\t': ' '}`
    )
    lyrics += "\n"
    line.map(({ chinese, pinyin }: any) =>
      lyrics += `${swapSwitch ? dictionaryCorrection(chinese, pinyin) : pinyinOnly ? chinese : ""}`
        + `${alignPinyin ? '\t': ' '}`
    )
    lyrics += "\n"
    return lyrics
  })
}

// function to correct incorrect pinyin eg. '祢' often gives 'mi' instead of 'ni'
const dictionaryCorrection = (chinese: string, pinyin: string) => {
  return chinese === '祢' ? 'ni' : pinyin
}

// function to generate numbers from a range (start to end) for dropdowns values
export const generateNumberRange = (start: number, end: number) => Array(end - start + 1).fill(1).map((_, idx) => start + idx)

// function to convert chinese text to pinyin
export const convertToPinyinChineseLyrics = (text: string): any[] => {
  const textWithCommas = text.replace(/\n\r?/g, '[newline]')
  // const textRemovedSpaces = textWithCommas.replace(/\s/g, '')

  // convert pinyin
  const pinyinValues = Pinyin(text, { keepOrigin: true })
  const pinyinWithCommas = pinyinValues.replace(/\n\r?/g, '[newline]') // Add commas for line spaces
  // console.log('pinyinWithCommas: ', pinyinWithCommas)
  
  let pinyinChineseLyrics = mapPinyinChineseLyrics(textWithCommas, pinyinWithCommas)

  return pinyinChineseLyrics
}

// function to convert chinese and pinyin to single array
const mapPinyinChineseLyrics = (raw: string, pinyin: string) => {
  let lyrics: any[] = []

  // split into lines array
  let rawLines = raw.split('[newline]')
  let pinyinLines = pinyin.split('[newline]')
  console.log('raw: ', rawLines)
  console.log('pinyin', pinyinLines)

  pinyinLines.forEach((line: any, index: number) => {
    let lyricLine: any[] = []
    // console.log('line', index + ': ' + line)
    
    let pinyinArray = line.split(/(\s+)/).filter((e: any) => e.trim().length > 0)
    // console.log('pinyinArray', pinyinArray)
    // console.log('rawLines ', rawLines[index])
    pinyinArray.map((item: any, id: number) => {
      return lyricLine.push({
        chinese: rawLines[index][id],
        pinyin: item,
      })
    })
    lyrics.push(lyricLine)
  })

  return lyrics
}

// function to download lyrics as txt file
export const downloadTxtFile = (lyrics: string) => {
  // const lyrics = formatLyrics(output).join().replace(',', '')
  const file = new File([lyrics], 'lyrics.txt', {type: 'text/plain;charset=utf-8'})
  saveAs(file)
}