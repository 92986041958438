import { BrowserRouter, Routes as AppRoutes, Route } from "react-router-dom"
import { CreatePPT, PinyinConvertor } from './pages'

// Components
import { Header } from './components'

const Routes = () => {
  return (
    <BrowserRouter>
      <Header />
      <AppRoutes>
        <Route path="/pinyin" element={<PinyinConvertor />} />
        <Route path="/" element={<CreatePPT />}>
        </Route>
      </AppRoutes>
    </BrowserRouter>
  )
}

export default Routes;
