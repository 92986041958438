import { Typography, AppBar, Toolbar } from '@mui/material'
import moment from 'moment'
import { styled } from '@mui/system';

const FooterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
}));
const FooterText = styled(Typography)(({ theme }) => ({
  width: '100%',
  alignItems: 'left',
  margin: theme.spacing(2, 0),
}));

export const Footer = () => (
  <FooterContainer>
    <AppBar position="static" sx={{ background: '#90acd3' }}>
      <Toolbar>
        <FooterText gutterBottom align="center">
          Kayee {moment().format('YYYY')} ~ Made with ❤️ and ☕.
          </FooterText>
      </Toolbar>
    </AppBar>        
  </FooterContainer>
);