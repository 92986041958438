import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material'


export const RadioButtonGroup = ({ value, setValue, values, label, isBibleVerse }: any) => {

  const [selectedValue, setSelectedValue] = React.useState(value)

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value)
    setValue(event.target.value)
  }

  const options = isBibleVerse
    ? values.filter((item: any) => !JSON.stringify(item).includes('&')) // only allow English or Chinese only
    : values

  return (
    <FormControl component="fieldset" sx={{ mt: 2 }}>
      <FormLabel component="legend">
        {label}
      </FormLabel>
      <RadioGroup aria-label="position" name="position" value={selectedValue} onChange={handleChange} row>
        {options.map((item: any, key: number) => (
          <FormControlLabel
            key={key}
            value={item.value ? item.value : item}
            control={<Radio color="primary" />}
            label={item.label ? item.label : item}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}