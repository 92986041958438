import { CssBaseline, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/system';

export const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: '#FFF',
  textDecoration: 'none',
  '&:focus, &:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
}));

export const Header = () => (
  <>
    <CssBaseline />
    <AppBar
      position="absolute"
      color="primary"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          sx={{ mr: '1rem' }}
          color="inherit"
          component={RouterLink}
          to="/" >
          <img width='30px' src={"icon.svg"} alt="Icon"/>
        </IconButton>
        <StyledLink to='/'>
          <Typography variant="h6">
            Bānānā Slides
          </Typography>
        </StyledLink>
      </Toolbar>
    </AppBar>
  </>
);