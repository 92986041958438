
export const EnglishTitle = 'Amazing Grace'

export const ChineseTitle = '奇異恩典'

export const ChineseLyrics = '奇異恩典，何等甘甜，\n'
  + '我罪已得赦免；\n'
  + '前我失喪，今被尋回，\n'
  + '瞎眼今得看見。\n'
  + '\n'
  + '浩大恩典，使我敬畏，\n'
  + '使我心得安慰；\n'
  + '初信之時即蒙恩惠，\n'
  + '真是何等寶貴。\n'
  + '\n'
  + '經過許多危險網羅，\n'
  + '飽受人間苦楚，\n'
  + '此恩領我平安渡過，\n'
  + '他日歸回天府。\n'
  + '\n'
  + '將來在天安居萬年，\n'
  + '恩光如日普照，\n'
  + '好像最初蒙恩景況，\n'
  + '讚美永不減少。\n'

export const EnglishLyrics = 'Amazing Grace! how sweet the sound\n'
  + 'That saved a wretch like me\n'
  + 'I once was lost, but now am found\n'
  + 'Was blind but now I see\n'
  + '\n'
  + 'Through many dangers, toils, and snares\n'
  + 'I have already come\n'
  + '\'Tis grace that brought me safe thus far\n'
  + 'And grace will lead me home\n'
  + '\n'
  + 'When we\'ve been there ten thousand years\n'
  + 'Bright, shining as the sun\n'
  + 'We\'ve no less days to sing God\'s praise\n'
  + 'Than when we first begun\n'
  + '\n'
  + 'Amazing Grace! how sweet the sound\n'
  + 'That saved a wretch like me\n'
  + 'I once was lost, but now am found\n'
  + 'Was blind but now I see\n'
  

export const EnglishBibleScripture = 'Psalms 119 : 1-12'

export const ChineseBibleScripture = '诗篇 119 : 1-12'

export const ChineseBibleVerses = '1行为完全、遵行耶和华律法的，这人便为有福！\n'
  + '2遵守他的法度、一心寻求他的，这人便为有福\n'
  + '3这人不做非义的事，但遵行他的道。\n'
  + '4耶和华啊，你曾将你的训词吩咐我们，为要我们殷勤遵守。\n'
  + '5但愿我行事坚定，得以遵守你的律例。\n'
  +'6我看重你的一切命令，就不至於羞愧。\n'
  +'7我学了你公义的判语，就要以正直的心称谢你。\n'
  + '8我 必 守 你 的 律 例 ； 求 你 总 不 要 丢 弃 我 ！。\n'
  + '9少 年 人 用 甚 麽 洁 净 他 的 行 为 呢 ？ 是 要 遵 行 你 的 话 ！\n'
  + '10我 一 心 寻 求 了 你 ； 求 你 不 要 叫 我 偏 离 你 的 命 令 。\n'
  + '11我 将 你 的 话 藏 在 心 里 ， 免 得 我 得 罪 你 。\n'

export const EnglishBibleVerses = '1 Blessed are those whose ways are blameless,\n'
  + 'who walk according to the law of the Lord.\n'
  + '2 Blessed are those who keep his statutes\n'
  + 'and seek him with all their heart—\n'
  + '3 they do no wrong but follow his ways.\n'
  +'4 You have laid down precepts\n'
  +'that are to be fully obeyed.\n'
  + '5 Oh, that my ways were steadfast\n'
  + 'in obeying your decrees!\n'
  + '6 Then I would not be put to shame\n'
  + 'when I consider all your commands.\n'
  + '7 I will praise you with an upright heart\n'
  + 'as I learn your righteous laws.\n'
  + '8 I will obey your decrees;\n'
  + 'do not utterly forsake me.\n'
  + '9 How can a young person stay on the path of purity?\n'
  + '10 I seek you with all my heart;\n'
  + 'do not let me stray from your commands.\n'
  + '11 I have hidden your word in my heart\n'
  + 'that I might not sin against you.\n'





















