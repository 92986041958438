import React from 'react'
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'

export const Switch = ({ setValue, label }: any) => {

  const [state, setState] = React.useState<any>(false)

  const handleSwitchChange = (event: any) => {
    setState(event.target.checked)
    setValue(state)
  }

  return (
    <FormControlLabel
      sx={{ m: 2 }}
      label={label}
      control={
        <MuiSwitch
          checked={state.checkedB}
          onChange={(e: any) => handleSwitchChange(e)}
          value="true"
          color="primary"
        />
      }
    />
  )
}