import PptxGenJS from 'pptxgenjs'
import Pinyin from 'chinese-transform-pinyin'

import {
  CHINESE_ENGLISH_PINYIN,
  // CHINESE_ENGLISH,
  CHINESE_PINYIN,
  CHINESE,
  ENGLISH,
  Title,
  PptGenConfig,
} from './types'

const lightTheme = {
  fontFace: 'Arial',
  fontColor: '000000',
  backgroundColor: 'FFFFFF'
}
const darkTheme = {
  fontFace: 'Arial',
  fontColor: 'FFFFFF',
  backgroundColor: '000000'
}

export class PPTGen {
  pptx: any//PptxGenJS

  constructor() {
    this.pptx = new PptxGenJS()
    this.pptx.layout = 'LAYOUT_4x3'
  }
  
  // add title slides to ppt with predefined formating
  async addTitleSlide(title: Title, { useChinese, useEnglish, usePinyin, theme }: PptGenConfig) {

    console.log('****** generatePPT ****** ')
    console.log('title: ', title)
    console.log('usePinyin: ', usePinyin)
    console.log('useChinese: ', useChinese)
    console.log('useEnglish: ', useEnglish)

    // Create Title Slide
    let titleSlideText = [
      usePinyin && {
        text: `${title.pinyin}\n`,
        options: { fontFace: theme.fontFace, fontSize: 32, color: theme.fontColor }
      },
      useChinese && {
        text: `${title.chinese}\n`,
        options: { fontFace: theme.fontFace, fontSize: 80, color: theme.fontColor } },
      useEnglish && {
        text: `${title.english}\n`,
        options: { fontFace: theme.fontFace, fontSize: 48, color: theme.fontColor }
      }
    ].filter(x => x)
    console.log('titleSlideText: ', titleSlideText)

    // Add Title Slide
    const titleSlide = await this.pptx.addNewSlide()
    titleSlide.background = { color: theme.backgroundColor }
    titleSlide.addText(titleSlideText, { x: 1.2, y: '50%', align: 'center', valign: 'middle' })
  }

  // add lyrics slides
  async addLyricSlides(lyricLines: any[], { useChinese, useEnglish, usePinyin, theme }: PptGenConfig) {
    // Distribute lines per slide
    let temp: any[] = []
    let mappedSlides: any[] = []

    lyricLines.forEach(line => {
      line && temp.push(line)
       if(!line) { // split by empty lines
        mappedSlides.push(temp)
        temp = []
      }
    })


    // Add Lyrics Slide
    mappedSlides.forEach(async (item) => {

      const slide = await this.pptx.addNewSlide()
      slide.background = { color: theme.backgroundColor }

      let lines: any[] = []

      item.forEach(({ chinese, pinyin, english }: Title) => {
        usePinyin && lines.push({
          text: `${pinyin}\n`,
          options: { fontSize: 24, color: theme.fontColor }
        })
        
        useChinese && lines.push({
          text: `${chinese || ""}\n`,
          options: {
            fontSize: (!useEnglish && !usePinyin) ? 48 : 40,
            color: theme.fontColor
          }
        })

        useEnglish && lines.push({
          text: `${english || ""}\n`,
          options: {
            fontSize: (useChinese && useEnglish && usePinyin)
              ? 24
              : (!useChinese && !usePinyin)
                ? 44 : 32,
            color: theme.fontColor
          }
        })

        lines.push({ text: ' \n', options: { fontSize: 20, color: theme.fontColor } })
      })

      if((!useEnglish && !usePinyin) || (!useChinese && !usePinyin))
        lines = lines.filter(line => line.text !== '')

      slide.addText(lines, { w: "100%", h: '100%', align: 'center', valign: 'middle' })

      lines = []
    })
    console.log('mappedSlides: ', mappedSlides)
  }

  // add bible verse slides
  async addBibleVerseSlides(title: Title, lines: any[], { useChinese, useEnglish, usePinyin, theme }: PptGenConfig) {
    // Distribute lines per slide
    let temp: any[] = []
    let mappedSlides: any[] = []

    lines.forEach(line => {
      line && temp.push(line)
       if(!line) { // split by empty lines
        mappedSlides.push(temp)
        temp = []
      }
    })
    console.log('Bible Verse mappedSlides: ', mappedSlides)

    // Add Slide
    mappedSlides.forEach(async (item: any) => {

      const slide = await this.pptx.addNewSlide()
      let lines = []

      const isNotNull = (value: any) => value && value !== ''

      const englishTitle = useEnglish && isNotNull(title.english) ? title.english : ''
      const chineseTitle = useChinese && isNotNull(title.chinese) ? title.chinese : ''
      let slideTitle = `${englishTitle} ${chineseTitle}`.trim()

      lines.push({ text: `${slideTitle}\n`, options: { bold: true, fontSize: 32, color: theme.fontColor } })

      item.forEach(({ chinese, english }: Title) => {
        
        useChinese && lines.push({
          text: `${chinese || ""}\n`,
          options: {
            fontSize: 24,
            color: theme.fontColor
          }
        })

        useEnglish && lines.push({
          text: `${english || ""}\n`,
          options: {
            fontSize: 24,
            color: theme.fontColor
          }
        })
        // Line Space
        lines.push({ text: '', options: { fontSize: 24, color: theme.fontColor } })
      })

      if((!useEnglish && !usePinyin) || (!useChinese && !usePinyin))
        lines = lines.filter(line => line.text !== '')

      slide.addText(lines, { x: 0.8, y: '50%', w: '90%', align: 'left', valign: 'middle' })

      lines = []
    })

    console.log('lines: ', lines)
  }

  // triggers ppt download
  async writeFile(title?: Title, config?: PptGenConfig) {
    const today = new Date()
    let fileName = `${today.getDate()}-${(today.getMonth()+1)}-${today.getFullYear()}`
    await this.pptx.writeFile({ fileName })
  }

}


// function to create single song ppt
export const generatePPT = async (
  chineseTitle: string,
  englishTitle: string,
  englishArray: any[],
  pinyinChineseArray: any[],
  languages: any,
  slideType: any,
  theme: any
) => {
  let title = createTitles(chineseTitle, englishTitle)
  let options = createOptions(languages, theme)
  let lines = prepropressLyrics(englishArray, pinyinChineseArray, options)
  
  let pptx = new PPTGen()

  if(slideType === 'Song') {
    await pptx.addTitleSlide(title, options)
    await pptx.addLyricSlides(lines, options)
  } else {
    await pptx.addBibleVerseSlides(title, lines, options)
  }
  await pptx.writeFile(title, options)
}

// function to create combined ppt
export const generateCombinedPPT = async (powerpoint: any) => {
  if(powerpoint.length > 0) {
    console.log('powerpoint: ', powerpoint)
    let pptx = new PPTGen()
    
    for(let i in powerpoint) {
      const { chineseTitle, englishTitle, englishArray, pinyinChineseArray, languages, slideType, linesPerSlide } = powerpoint[i]

      let title = createTitles(chineseTitle, englishTitle)
      let options = createOptions(languages, linesPerSlide)
      let lines = prepropressLyrics(englishArray, pinyinChineseArray, options)
  
      if(slideType === 'Song') {
        await pptx.addTitleSlide(title, options)
        await pptx.addLyricSlides(lines, options)
      } else {
        await pptx.addBibleVerseSlides(title, lines, options)
      }
    }
    
    await pptx.writeFile()
  }
}


// function to create title object with english/chinese/pinyin titles
const createTitles = (chineseTitle: string, englishTitle: string) =>  {
  return {
    pinyin: Pinyin(chineseTitle, { keepOrigin: true }),
    chinese: chineseTitle,
    english: englishTitle
  }
}

// function to create options object
const createOptions = (languages: string, theme: any) =>  {
  return {
    theme: theme === 'Dark' ? darkTheme : lightTheme,
    useChinese: languages !== ENGLISH,
    useEnglish: languages !== CHINESE && languages !== CHINESE_PINYIN,
    usePinyin: languages === CHINESE_ENGLISH_PINYIN || languages === CHINESE_PINYIN
  }
}

// maps pinyin and chinese to align correctly
export const prepropressLyrics = (englishArray: any[], pinyinChineseArray: any[], { useChinese, useEnglish, usePinyin }: PptGenConfig) => {

  let chineseLines: any[] = []
  let pinyinLines: any[] = []
  let englishLines: any[] = englishArray.length > 0 ? englishArray : []
  
  if(usePinyin) {
    pinyinLines = pinyinChineseArray.map((line: any) => {
      let pinyinLine = line.reduce((prev: any, { pinyin }: any) => [...prev, pinyin], []).join(' ')
      return pinyinLine
    })
  }

  if(useChinese) {
    chineseLines = pinyinChineseArray.map((line: any) => {
      let chineseLine = line.reduce((prev: any, curr: any) => [...prev, curr.chinese], []).join(' ')
      return chineseLine
    })
  }

  console.log('chineseLines: ', chineseLines)
  console.log('pinyinLines: ', pinyinLines)
  console.log('englishArray: ', englishLines)

  let lines = []
  if(useChinese) {
    lines = chineseLines.map((chinese: string, index: number) => {
      return !chinese ? null : {
        chinese,
        pinyin: pinyinLines[index],
        english: englishLines.length > 0 ? englishLines[index] : ''
      }
    })
  } else {
    lines = englishLines.map((english: string, index: number) => {
      return !english ? null : {
        chinese: '',
        pinyin: '',
        english
      }
    })
  }
  console.log('prepropressLyrics: ', lines)
  return lines
}