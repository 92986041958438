import { createTheme, ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from './theme';
import Routes from './routes';


const App = () => {
  const theme = createTheme(defaultTheme);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
