import { useState } from 'react'
import { Grid } from '@mui/material'
import { RadioButtonGroup, Dropdown } from '../../components'
import {
  RootContainer,
  Heading,
  SubHeading,
  StyledCard,
  StyledContainer,
  InputContainer,
  StyledTextField,
  StyledTextArea,
  ButtonsContainer,
  StyledButton
} from './ppt-gen.styled'
import { generatePPT, generateCombinedPPT } from './ppt-gen.util'
import { isChinese, convertToPinyinChineseLyrics, stringToArray } from '../../common/utils'

import * as testData from '../../common/mock-data'
import { CHINESE_ENGLISH_PINYIN, CHINESE_PINYIN, CHINESE, ENGLISH, languageOptions } from './types'


export const CreatePPT = () => {

  const [errorMessage, setErrorMessage] = useState('')
  const [pinyinChineseArray, setPinyinChineseArray] = useState<any[]>([])
  const [englishArray, setEnglishArray] = useState<any[]>([])
  
  const [chineseTextArea, setChineseTextArea] = useState('')
  const [englishTextArea, setEnglishTextArea] = useState('')

  const [chineseTitle, setChineseTitle] = useState('')
  const [englishTitle, setEnglishTitle] = useState('')
  const [theme, setTheme] = useState('Light')
  const [languages, setLanguages] = useState(CHINESE)
  const [slideType, setSlideType] = useState('Song')
  const [powerpoint, setPowerpoint] = useState<any[]>([])
  
  const isBibleVerse = slideType === 'Bible Verse'
  const disabledButtons = (languages.includes('Chinese') && chineseTextArea === '')
    || (languages.includes('English') && englishTextArea === '')

  // stores the chinese/pinyin lyrics as user types
  const chineseTextAreaHandler = (text: string) => {
    if(languages.includes('Chinese')) {
      setChineseTextArea(text)
      if (isChinese(text)) {
        setErrorMessage("")
        setPinyinChineseArray(convertToPinyinChineseLyrics(text))
      } else {
        setErrorMessage("Please enter chinese characters")
        console.log(errorMessage)
      }
    }
  }

  // store the english lyrics as user types
  const englishTextAreaHandler = (text: string) => {
    if(languages.includes('English')) {
      setEnglishTextArea(text)
      setEnglishArray(stringToArray(text))
    }
  }

  // triggers ppt generation
  const save = () => {
    generatePPT(chineseTitle, englishTitle, englishArray, pinyinChineseArray, languages, slideType, theme)
  }

  // fill textfields with test song data for quickly generating a sample ppt
  const applyTestData = () => {
    setEnglishTitle(isBibleVerse ? testData.EnglishBibleScripture : testData.EnglishTitle)
    setEnglishTextArea(isBibleVerse ? testData.EnglishBibleVerses : testData.EnglishLyrics)

    setChineseTitle(isBibleVerse ? testData.ChineseBibleScripture : testData.ChineseTitle)
    setChineseTextArea(isBibleVerse ? testData.ChineseBibleVerses : testData.ChineseLyrics)

    const chineseArray = convertToPinyinChineseLyrics(
      isBibleVerse ? testData.ChineseBibleVerses : testData.ChineseLyrics
    )
    setPinyinChineseArray(chineseArray)

    const englishArray = stringToArray(
      isBibleVerse ? testData.EnglishBibleVerses : testData.EnglishLyrics
    )
    setEnglishArray(englishArray)
  }

  const onEdit = (index: number) => {

    // const {
    //   chineseTitle,
    //   englishTitle,
    //   rawChinese,
    //   rawEnglish,
    //   languages
    // } = powerpoint[index]

    // console.log('[index]: ', index)
    // console.log('[powerpoint[index]: ', powerpoint[index])
    // setLanguages(languages)

    // setEnglishTitle(englishTitle)
    // setEnglishTextArea(rawEnglish)

    // setChineseTitle(chineseTitle)
    // setChineseTextArea(rawChinese)
  }

  // Push song object to powerpoint lists
  const addMoreSongs = () => {
        
      const id = `slide-${powerpoint.length}${Math.round(Math.random() * 1000)}`
      const ppt = {
        id,
        chineseTitle,
        englishTitle,
        pinyinChineseArray,
        englishArray,
        rawChinese: chineseTextArea,
        rawEnglish: englishTextArea,
        languages,
        slideType
      }
      setPowerpoint([ ppt, ...powerpoint ])
      console.log('powerpoint: ', powerpoint)
    // }
  }

  const clear = () => {
    setErrorMessage('')
    setPinyinChineseArray([])
    setEnglishArray([])
    setLanguages(CHINESE_ENGLISH_PINYIN)
    setSlideType('Song')

    setEnglishTitle('')
    setEnglishTextArea('')

    setChineseTitle('')
    setChineseTextArea('')

  }

  const combineDownload = () => {
    //TODO: combine ppt object
    
    generateCombinedPPT(powerpoint)
  }

  return (
    <RootContainer>
      <Heading>Create PPT</Heading>

      {/* Start of Creat PPT Form */}
      <StyledCard>
        <StyledContainer>
          <SubHeading>Slide Settings</SubHeading>
          <Grid container>
{/*             <Grid item xs={12}>
              <RadioButtonGroup
                label="Theme"
                value={theme}
                setValue={(value: any) => setTheme(value)}
                values={['Light', 'Dark']} />
            </Grid> */}
            <Grid item xs={12}>
              <RadioButtonGroup
                label="Slide Type"
                value={slideType}
                setValue={(value: any) =>  setSlideType(value)}
                values={['Song', 'Bible Verse']} />
            </Grid>
            <Grid item xs={12}>
              <RadioButtonGroup
                isBibleVerse={isBibleVerse}
                label="Languages"
                value={languages}
                setValue={setLanguages}
                values={languageOptions} />
            </Grid>
          </Grid>
        </StyledContainer>
      </StyledCard>

      <StyledCard>
        <StyledContainer>
          <SubHeading>
            {`Add ${slideType} Slide`}
          </SubHeading>

          <SubHeading>Add Title</SubHeading>
          <InputContainer>
            {languages !== ENGLISH && (
              <StyledTextField
                fullWidth
                label={`Chinese ${isBibleVerse ? 'Scripture Heading' :'Slide Title'}`}
                variant="outlined"
                value={chineseTitle || ''}
                onChange={e => setChineseTitle(e.target.value)}
              />
            )}
            {languages !== CHINESE && languages !== CHINESE_PINYIN && (
              <StyledTextField 
                fullWidth
                label={`English ${isBibleVerse ? 'Scripture Heading' :'Slide Title'}`}
                variant="outlined"
                value={englishTitle || ''}
                onChange={e => setEnglishTitle(e.target.value)}
              />
            )}
          </InputContainer>

          {/* Lyrics Section */}
          <SubHeading>
            Add {isBibleVerse ? 'Verses' : 'Lyrics'}
          </SubHeading>
          <InputContainer>
            {languages !== ENGLISH && (
              <StyledTextArea
                minRows={10}
                id="chineseLyrics"
                placeholder={`Enter Chinese ${isBibleVerse ? 'Bible Verses' :'lyrics'}`}
                value={chineseTextArea || ''}
                onChange={e => chineseTextAreaHandler(e.target.value)}
              />
            )}
            {languages !== CHINESE && languages !== CHINESE_PINYIN && (
              <StyledTextArea
                minRows={10}
                id="englishLyrics"
                placeholder={`Enter English ${isBibleVerse ? 'Bible Verses' :'lyrics'}`}
                value={englishTextArea || ''}
                onChange={e => englishTextAreaHandler(e.target.value)}
              />
            )}
          </InputContainer>
          
          {/* Buttons */}
          <ButtonsContainer>
            <StyledButton disabled={disabledButtons} color="primary" variant="contained" onClick={() => save()}>
              Generate PPT
            </StyledButton>
            {/* <StyledButton disabled={disabledButtons} color="primary" variant="outlined" onClick={() => addMoreSongs()}>
              Add another song
            </StyledButton> */}
            <StyledButton disabled={disabledButtons} color="primary" variant="outlined" onClick={() => clear()}>
              Clear
            </StyledButton>
            <StyledButton color="primary" variant="outlined" onClick={() => applyTestData()}>
              Apply Test Data
            </StyledButton>
          </ButtonsContainer>

        </StyledContainer>
      </StyledCard>
      {/* End of Creat PPT Form */}


        {/* Preview Plane */}
        {/* <Grid item sm={4}>
          <Hidden smDown>
            <Card className={classes.preview} >
              <CardContent>
                <Typography variant="h5" className={classes.subHeading}>Preview</Typography>

                <DragAndDropList items={powerpoint} setItems={setPowerpoint} onEdit={onEdit} />
                
                {powerpoint.length > 0 &&
                  <>
                    <Button
                      className={classes.previewItem}
                      onClick={() => combineDownload()}
                      color="primary"
                      variant="contained">
                      Combine & Generate
                    </Button>
                    <Typography variant="body2">
                      Number of Songs: {powerpoint.length}
                    </Typography>
                  </>
                }
              </CardContent>
            </Card>
          </Hidden>
        </Grid> */}

    </RootContainer>
  )
}
