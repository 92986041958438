import { styled } from '@mui/system';
import { Grid, TextareaAutosize, TextField, Typography, Button, Hidden, Card, CardContent } from '@mui/material'


export const RootContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  height: '100%',
}));

export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'vertical'
}));

export const OutputContainer = styled('div')(({ theme }) => ({
  minHeight: '300px',
  outline: 0,
  position: 'relative',
  justifyContent: 'center',
  borderRadius: '10px',
  padding: theme.spacing(1),
  backgroundColor: '#f5f5f5',
}));

export const Line = styled(Typography)(({ theme }) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'left',
  position: 'relative',
  padding: theme.spacing(1),
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
}));
